<script setup lang="ts">
const { t } = useT();

const { depositInfoData, durationLeftResetStreak } = useTaoDepositStreak();

const activeDepositCount = computed(() => depositInfoData.value?.progress?.lastIndexOf(true) + 1);
const depositsTotals = computed(() => depositInfoData.value?.progress?.length);

const title = computed(() => (depositInfoData.value?.canWheel ? t("Get my prize") : t("Streak to win")));
</script>

<template>
	<div class="box-deposit-streak">
		<AText v-if="depositInfoData?.dailyWinStreakLimitExceeded" :size="12" class="text-coro">
			<i18n-t keypath="Available in: {key}">
				<template #key>
					<br />
					<AText :size="16" class="text-coro" :modifiers="['semibold']">
						{{ durationLeftResetStreak }}
					</AText>
				</template>
			</i18n-t>
		</AText>
		<template v-else>
			<AText :modifiers="['uppercase', 'bold']" gradient="var(--grahamstown)">
				<span>{{ title }}</span>
			</AText>
			<AText v-if="depositInfoData?.canWheel" :size="12" class="text-coro">
				{{ t("Prize Unlocked") }}
			</AText>
			<MDepositStreakLevelsBar v-else class="levels" :active="activeDepositCount" :levels="depositsTotals" />
		</template>
	</div>
</template>

<style lang="scss" scoped>
.box-deposit-streak {
	display: flex;
	flex-direction: column;
	justify-content: center;

	.levels {
		margin-top: 4px;
	}

	.label {
		display: inline-flex;
		position: absolute;
		left: -3px;
		top: -3px;
		border-radius: 33px;
		padding: 4px 8px;
		border: none;

		&:deep(span) {
			text-shadow: 0px 1px 1px var(--coronel);
		}
	}
}
</style>
